import type { Schemas } from '#shopware'

export const useProductStream = (streamId: string) => {
  const { apiClient } = useShopwareContext()

  const search = async (criteria: Schemas['Criteria']) => {
    return await apiClient.invoke(`readProductStream post /product`, {
      body: {
        ...criteria,
        filter: [
          {
            type: 'equalsAny',
            field: 'streamIds',
            value: [streamId],
          },
          ...(criteria.filter || []),
        ],
      },
    })
  }

  return {
    search,
  }
}
